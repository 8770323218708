  .carousel-with-custom-dots {
    margin-top: 100px;

  }
  .custom-dot {
    border: none;
    margin: 10px;
    outline: none;
  }
  .custom-dot--active {
    transform: scale(1.3);
   
    background-color: black;
    
  }