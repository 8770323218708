.MuiDialog-paper {
  margin: 15px !important;
  border-radius: 0 !important;
}
.MuiDialog-paper .MuiDialogContent-root {
  max-width: 100%;
}
#customized-dialog-title {
  padding: 16px 24px 0;
  margin-bottom: 20px;
}
#customized-dialog-title h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#customized-dialog-title span {
  line-height: 1;
}
#customized-dialog-title .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
}
.close-dialog {
  cursor: pointer;
}
.select-dialog h2 span {
  font-size: 18px;
  line-height: 22px;
  color: #121212;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}
.select-dialog ul li,
.select-dialog ul li a {
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.select-dialog ul li:last-child {
  border: none;
}
.select-dialog .custom-radio span {
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
/* dummy size chart dialog */
.dummy-chart-dialog .MuiDialog-paper {
  max-width: 835px;
}

/* cancel order dialog */
.cancel-order {
  text-align: center;
}
.cancel-order #customized-dialog-title h2 {
  justify-content: flex-end;
}
.cancel-order .btn-wrapper {
  display: flex;
  justify-content: space-between;
}
.cancel-order .btn-wrapper button {
  width: 46%;
  min-width: unset;
  min-height: 52px;
}
.cancel-order .modal-heading {
  font-size: 18px;
  line-height: 22px;
  color: #121212;
  font-weight: 600;
  margin: 0 0 20px;
}
.cancel-order .sub-txt {
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  font-weight: 400;
  margin-bottom: 39px;
}
.cancel-order .MuiDialog-paper .MuiDialogContent-root {
  padding: 38px 30px 28px;
}

/* cancel order reason dialog */
.cancel-order-reason .sub-txt {
  max-width: 300px;
  margin: 0 auto 43px;
  text-align: center;
}
.cancel-order-reason .btn-wrapper button {
  width: 100%;
}
.cancel-order-reason #customized-dialog-title {
  padding: 16px 16px 0 30px;
}
.cancel-order-reason .MuiDialog-paper .MuiDialogContent-root {
  padding: 34px 30px 39px;
}

/* order dialog common */
.cancel-order .MuiDialog-paper,
.order-dialog .MuiDialog-paper {
  border-radius: 0;
}
.cancel-order #customized-dialog-title,
.order-dialog #customized-dialog-title {
  padding: 16px 16px 0;
  margin-bottom: 0;
}
.cancel-order #customized-dialog-title .MuiSvgIcon-root,
.order-dialog #customized-dialog-title .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
}
.order-dialog .btn-wrapper button.full-width {
  width: 100%;
}
.order-dialog .btn-wrapper button {
  min-height: 52px;
}
.order-dialog .MuiFormLabel-root {
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 4px;
}
.order-dialog .MuiSelect-select {
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}
.order-dialog .MuiInputBase-root {
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
}
.order-dialog .MuiFormControl-root {
  width: 100%;
}
.order-dialog .form-group {
  margin-bottom: 53px;
}
.order-dialog .custom-radio span {
  font-weight: 600;
  padding: 0;
}
.order-dialog .custom-radio .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
}
.order-dialog .custom-radio .MuiFormControlLabel-label {
  margin-left: 6px;
}
.order-dialog .MuiFormControlLabel-root {
  margin-left: 0;
  margin-bottom: 9px;
}
.order-dialog .modal-heading {
  font-size: 18px;
  line-height: 22px;
  color: #121212;
  font-weight: 600;
  margin: 0 0 20px;
}
.order-dialog .sub-txt {
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  font-weight: 400;
  margin-bottom: 39px;
}
.order-dialog h2 span {
  font-size: 18px;
  line-height: 22px;
  color: #121212;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.order-dialog .productlist-wrapper {
  padding: 0;
}
.order-dialog .productlist-wrapper .product-list {
  padding: 15px 21px 18px;
  border: 1px solid #d3d3d3;
}
.order-dialog .order-details-list {
  width: 100%;
  margin: 0;
}
.order-dialog .order-details-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.order-dialog .order-details-list li:last-child {
  margin-bottom: 0;
}
.order-dialog .productlist-wrapper .product-desc {
  color: #5d5d66;
  margin-bottom: 15px;
}
.order-dialog .productlist-wrapper .size-qty span {
  margin-right: 28px;
}
.order-dialog .productlist-wrapper .product-name {
  font-size: 16px;
  line-height: 20px;
  color: #121212;
}
.order-dialog .productlist-wrapper .product-list .product-img {
  width: 110px;
  height: 135px;
}
.order-dialog .order-details-list .order-status {
  margin: 0;
}
.order-dialog .order-details-list .order-heading {
  margin-bottom: 0;
}
.order-dialog .order-heading {
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  font-weight: 600;
  margin: 0 0 15px;
  width: 100%;
}
.order-dialog .current-status {
  font-size: 16px;
  line-height: 20px;
  /* color: #ff8030; */
  font-weight: 600;
  margin-bottom: 15px;
}
.order-dialog .current-status.cancelled {
  color: #f94a59;
}
.current-status.confirmed {
  color: #21c998;
  background: #e8f9f4;
}
.current-status.delivered {
  color: #ff8030;
  background: #fff2ea;
}
.current-status.rto {
  color: #517ba3;
  background: #edf1f6;
  /* text-transform: uppercase; */
}
.current-status.exchange {
  color: #cf20ff;
  background: #fae8ff;
}
.current-status.cancelled {
  color: #f94a59;
  background: #fdcdd0;
}
.current-status.refunded {
  color: #775aff;
  background: #f1eeff;
}
.order-dialog .order-label {
  font-size: 14px;
  line-height: 18px;
  color: #5d5d66;
  font-weight: 400;
  margin-right: 10px;
}
.order-dialog .order-desc {
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  font-weight: 500;
}
.order-dialog .order-name {
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  font-weight: 600;
  margin: 0 0 10px;
  width: 100%;
}
.order-dialog p {
  font-size: 14px;
  line-height: 20px;
  color: #5d5d66;
  font-weight: 400;
  margin: 0 0 10px;
}
.order-dialog p:last-child {
  margin: 0;
}
.order-dialog .order-details-list .order-total .order-label,
.order-dialog .order-details-list .order-total .order-desc {
  font-weight: 600;
}

/* order schedule */
.order-schedule {
  text-align: center;
}
.order-schedule .MuiDialog-paper .MuiDialogContent-root {
  padding: 18px 30px 38px;
}
.order-schedule .modal-heading {
  line-height: 23px;
  margin: 0 0 40px;
}
.order-schedule #customized-dialog-title h2 {
  justify-content: flex-end;
}

/* Change Pickup Address */
.select-dialog ul {
  margin: 0;
}
.select-dialog .address-detail {
  padding-left: 36px;
}
.select-dialog .address {
  font-size: 14px;
  line-height: 20px;
  color: #5d5d66;
  font-weight: 400;
}
.select-dialog .btn-wrapper {
  text-align: center;
  margin-top: 43px;
}
.select-dialog .btn-wrapper button {
  min-height: 50px;
  max-width: 200px;
}
.default-link {
  font-size: 12px;
  line-height: 16px;
  color: #121212;
  border: 1px solid #121212;
  padding: 3px 8px;
  font-weight: 500;
  border-radius: 3px;
  text-decoration: none;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-top: 15px;
  display: inline-block;
  text-transform: uppercase;
}
.default-link:hover {
  color: #ffffff;
  background: #121212;
}
.pickup-address .MuiDialogContent-root {
  padding: 16px 28px 40px;
}
.pickup-address #customized-dialog-title {
  padding: 16px 16px 0 30px;
}
.pickup-address h2 span {
  font-size: 24px;
  line-height: 30px;
}

/* Choose Package Dimensions */
.package-dimensions .MuiDialogContent-root {
  padding: 20px 28px 10px;
}
.package-dimensions #customized-dialog-title {
  padding: 16px 16px 0 30px;
}
.package-dimensions.select-dialog ul li,
.package-dimensions.select-dialog ul li a {
  padding: 18px 0 20px;
}
/* order detail */
.order-details #customized-dialog-title {
  padding: 16px 16px 0 28px;
}
.order-details .MuiDialogContent-root {
  padding: 32px 28px 35px;
}


/* mobile verification dialog */
.phone-verify h2 span {
	font-size: 18px;
	line-height: 20px;
	color: #212121;
	font-weight: 600;
}
.phone-verify .MuiDialogTitle-root {
	margin:  0 0 8px !important;
}
.phone-verify .phone-verify-content p {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	color: #121212;
	margin:  0 0 25px;
}
.phone-verify .phone-verify-content p a {
	color:  inherit;
	text-decoration: none;
}
.phone-verify .phone-verify-content p span {
	font-weight: 600;
}
.phone-verify .btn-wrapper {
	text-align: center;
	margin-bottom: 20px;
}
/* order detail pages dialog css */
.order-info-blog {
  border-top: 1px solid #e3e3e3;
  padding: 25px 0 0;
}
.order-info-blog h4 {
  font-weight: 600;
  color: #212121;
  margin: 0 0 15px 0;
}
.rating-wrapper .rating p {
  font-size: 16px;
  line-height: 20px;
  color: #121212;
  font-weight: 600;
  margin: 0 0 15px;
}
.rating-wrapper .product-rating {
  margin-bottom: 20px;
}
.rating-wrapper .product-rating .MuiIconButton-root{
  padding: 0;
  margin-right: 9px;
}
.rating-wrapper .product-rating .MuiIconButton-label span.fill {
  display: none;
}
.rating-wrapper .product-rating .MuiIconButton-label span.fill .MuiSvgIcon-root{
  color: #ffc107;
}
.rating-wrapper .product-rating .MuiIconButton-label .MuiSvgIcon-root {
  color: #b6b6b6;
  width: 30px;
  height: 30px;
}
.rating-wrapper .product-rating .MuiIconButton-root.active .MuiIconButton-label span,
.rating-wrapper .product-rating .MuiIconButton-root:hover .MuiIconButton-label span {
  display: none;
}
.rating-wrapper .product-rating .MuiIconButton-root.active .MuiIconButton-label .fill,
.rating-wrapper .product-rating .MuiIconButton-root:hover .MuiIconButton-label .fill {
  display: inline-block;
}
.rating-wrapper .btn-wrapper {
  text-align: center;
}
.rating-wrapper .btn-wrapper .outline-btn {
  margin-right: 15px;
}
.order-dialog .order-info-blog .form-group {
  margin-bottom: 25px;
}
.mrb-25 {
  margin-bottom: 25px;
}
.image-outer {
  display: flex
}
.image-wrapper {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 5px;
  background: #aaa;
  margin: 0 10px 10px 0
}
.image-wrapper .main-img{
  height: 100%;
  width: 100%;
  object-fit: cover
}
.image-wrapper .cross-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  background-color: black;
  border-radius: 50%
}
.upload-file-block {
  width: 82px;
  height: 82px;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid rgba(18,18,18,0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  margin-top: 10px;
}
.upload-file-block svg {
  color: rgba(18,18,18,0.15);
}
.upload-file-block input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
 .upload-file-block input::-webkit-file-upload-button {
  cursor: pointer;
}


/* Shopping Bag */
.product-dialog .product-item-size {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 25px;

}
.product-dialog .product-item-size li {
  padding: 0 5px;
  flex: 0 0 20%;
  max-width: 20%;
  margin-bottom: 10px;
}




/* .product-dialog .product-item-size li a  {
  border: 1px solid black;
}
 */





.product-dialog .product-item-size li a {
  border: 1px solid #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  color: #212121;
  font-weight: 600;
  text-decoration: none;
  padding: 13px;
}
.product-dialog .black-btn {
  width: 100%;
  margin-bottom: 25px;
}

/* apply coupon */
.apply-coupon-modal .form-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 30px;
  padding: 0 0 030px;
  border-bottom: 1px solid #e3e3e3;

}
.apply-coupon-modal .form-wrapper .form-group {
  margin-bottom: 0;
    flex: 0 0 75%;
    max-width: 75%;
  
}
.apply-coupon-modal .form-wrapper .black-btn {
    flex: 0 0 20%;
    max-width: 20%;
    min-width: 1px;
}
.coupon-list {
  padding: 15px;
  border: 1px solid #d3d3d3;
  margin-bottom: 20px;
  display:flex;
  flex-wrap: wrap;
    cursor:pointer
}
.coupon-list .discount {
  width: 100px;
  height: 100px;
  background: #f6f6f6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  font-size: 24px;
  line-height: 28px;
  color:#21c998;
  font-weight: 600;
  text-align: center;
}

.coupon-list .discount img {
width:100%;
height:100%;
}



.coupon-list .discount-details {
  flex: 1;
}
.coupon-list .discount-details p{
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  margin: 0 0 15px;
}
.coupon-list .discount-details .code {
  color:#b6b6b6;
  
} 
.coupon-list .discount-details .code span{
  color:#f94a59;
  font-weight: 500;
}
.coupon-list .discount-details .code-apply {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.coupon-list .discount-details .code-apply button {
  height: 32px;
  min-width: 100px;
}
.coupon-list .discount-details .code-apply a {
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  font-weight: 500;
  text-decoration: none;
}
.coupon-list .apply-coupun-details {
  width: 100%;
  display:none
}
.coupon-list .apply-coupun-details ul {
  margin: 20px 0 0;
}
.coupon-list .apply-coupun-details ul li {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: #121212;
  margin-bottom: 10px;
  padding-left: 12px;
}
.coupon-list .apply-coupun-details ul li span {
  opacity: 0.5;
}
.coupon-list .apply-coupun-details ul li:after{
  content: '';
  position: absolute;
  top: 50%;
  left:0;
  width: 4px;
  height: 4px;
  background: #212121;
  border-radius: 12px;
  transform: translateY(-50%);
}
.payment-dialog .payment-wrapper .form-group {
  margin-bottom: 35px;
}
.payment-dialog .payment-wrapper .black-btn {
  margin-bottom: 30px;
  width: 100%;
}
.payment-dialog .payment-wrapper .form-group .MuiFormControlLabel-root{
  margin-left: 0;
  font-size: 12px;
  line-height: 16px;
  color: #121212;
}
.payment-dialog .payment-wrapper .form-group .MuiFormControlLabel-root .MuiIconButton-root{
  color: #212121 !important;
}
@media (max-width: 767px)  {
  .payment-dialog .payment-wrapper .form-group {
    margin-bottom: 25px;
  }
  .payment-dialog .payment-wrapper .black-btn {
    margin-bottom: 20px;
  }
}
@media (max-width: 375px) {
  .product-dialog .product-item-size li {
    flex: 0 0 25%;
    max-width: 25%;
  }
}


@media (max-width: 575px) {
  .rating-wrapper .btn-wrapper button {
    width: 100%;
  }
  .rating-wrapper .btn-wrapper .outline-btn {
    margin: 0 0 15px;
  }
}

@media (max-width: 767px) {
  /* order dialog common */
  .order-dialog h2 span {
    font-size: 16px;
  }
  .order-dialog .order-label {
    width: 100%;
    margin: 0 0 5px;
  }
  .order-dialog .order-heading {
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 10px;
  }
  .order-dialog .order-details-list li {
    margin-bottom: 12px;
  }
  .order-dialog .productlist-wrapper .product-list {
    padding: 12px;
    border: 1px solid #d3d3d3;
  }
  .order-dialog .productlist-wrapper .product-list .product-img {
    width: 60px;
    height: 85px;
  }
  .order-dialog .productlist-wrapper .product-name {
    font-size: 14px;
    line-height: 18px;
  }
  .order-dialog .productlist-wrapper .product-desc {
    margin-bottom: 5px;
  }
  .order-dialog .current-status {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }
  .cancel-order .MuiDialog-paper .MuiDialogContent-root,
  .order-dialog .MuiDialog-paper .MuiDialogContent-root {
    padding: 20px 15px;
  }
  .cancel-order #customized-dialog-title .MuiSvgIcon-root,
  .order-dialog #customized-dialog-title .MuiSvgIcon-root {
    width: 25px;
    height: 25px;
  }
  .cancel-order #customized-dialog-title,
  .order-dialog #customized-dialog-title {
    padding: 12px 15px 0;
  }
  .order-dialog .btn-wrapper button {
    min-height: 40px;
    padding: 6px 8px;
  }
  .order-dialog .MuiSelect-select {
    font-size: 14px;
    line-height: 18px;
  }
  .order-dialog .MuiInputBase-root {
    font-size: 14px;
    line-height: 18px;
  }
  .order-dialog .form-group {
    margin-bottom: 25px;
  }
  .order-dialog .custom-radio .MuiSvgIcon-root {
    width: 25px;
    height: 25px;
  }

  /* cancel order */
  .cancel-order .btn-wrapper button {
    min-height: 40px;
    padding: 6px 8px;
  }

  /* cancel order reason */
  .cancel-order-reason .sub-txt {
    margin: 0 auto 25px;
  }

  /* order schedule */
  .order-schedule .modal-heading {
    margin: 0 0 25px;
  }

  /* Change Pickup Address */
  .select-dialog .btn-wrapper {
    margin-top: 18px;
  }
  .pickup-address h2 span {
    font-size: 18px;
  }
}
