.product-container.MuiContainer-root {
    max-width: 1355px;
    padding: 0 16px;
  }
  .product-wrappers h5 {
    font-size: 18px;
    line-height: 22px;
    color: #121212;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .product-wrappers h5 span {  
    color: #5d5d66;
    font-weight: normal;
  }
  .product-items {
    width: auto !important;
    margin: 0 -30px;
    

  }
  .product-items .product-item {
    padding: 0 30px;
    margin-bottom: 47px;
    
    
  }
  .product-items .product-item-photo {
    position: relative;
    padding: 0;
    width: 100%;
    margin-bottom: 15px;
    background-color: #fff;
    display: inline-block;
    cursor:pointer
    
  }
  .product-items .product-image-wrapper {
    /* width: 285px; */
    /* height: 360px; */
    max-width: 100%;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
  }
  .product-items .product-image-wrapper img {
    display: block;  
    max-width: 100%;
    height: 300px;
    width: 100%;
    /* object-fit: contain; */
  }
  .product-items .stock-noavailable .product-image-wrapper img {
    opacity: 0.5;
  }
  .product-items .action-link {
    position: absolute;
    width: 27px;
    height: 27px;
    top: 11px;
    right: 11px;
    background-color: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    z-index: 2;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .product-items .action-link:hover {
    background: #e5e5ee;
  }
  .product-items .action-link img {
    max-width: 100%;
  }
  .product-items .action-link.product-cart {  
    background-color: #ffffff;  
    left: 12px;
    bottom: 12px;  
    top: auto;
    right: auto;

  }
  .product-items .product-cart:hover {
    background: #e5e5ee;
    cursor:pointer
  }
  .product-items .stock-info {
    position: absolute;
    background-color: white;
    width: 170px;
    max-width: 100%;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: block;
    padding: 10px;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    font-weight: normal;
    z-index: 1;
  }
  .product-items .addbag-btn {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    min-width: unset;
    padding: 7px;
    text-transform: uppercase;
  }
  .product-items .product-brand {
    font-size: 16px;
    line-height: 20px;
    color: #121212;
    font-weight: 600;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  .product-items .product-name .product-link {
    font-size: 14px;
    line-height: 20px;
    color: #5d5d66;
    margin-bottom: 10px;
    text-transform: capitalize;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
  }
  .product-items .pricing-wrapper .price {
    font-size: 16px;
    line-height: 20px;
    color: #121212;
    font-weight: 600;
    margin-right: 10px;
  }
  .product-items .pricing-wrapper .old-price {
    font-size: 12px;
    line-height: 20px;
    text-decoration: line-through;
    color: #a5a5a5;
    font-weight: normal;
  }
  .product-items .pricing-wrapper .offer {
    font-size: 12px;
    line-height: 20px;
    color: #ff4949;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  @media (max-width: 1366px) {  
    .product-items {
      margin: 0 -15px;
    }
    .product-items .product-item {
      padding: 0 15px;
      margin-bottom: 25px;
    }
  }
  
  @media (max-width: 1199px) {
    .product-wrappers h5 {
      font-size: 16px;
      line-height: 20px;   
      margin-bottom: 20px;
    }
    .product-items {
      margin: 0 -10px;
    }
    .product-items .product-item {
      padding: 0px 10px;
    }
    .product-items .stock-info {    
      width: 150px;   
      padding: 8px 10px;
      font-size: 14px;
      line-height: 18px;    
    }  
    .product-items .product-brand {
      font-size: 14px;
      line-height: 18px;
    }
    .product-items .addbag-btn {    
      font-size: 12px;
      line-height: 16px;
    }
  }
  
  @media (max-width: 767px) {  
    .product-items {
      margin: 0 -7px;
    }
    .product-items .product-item {
      padding: 0px 7px;
    }
    .product-wrappers h5 {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 12px;
    } 
    .product-items .pricing-wrapper .price {
      font-size: 14px;
      line-height: 18px;
      
    }
    .product-items .stock-info {
      width: 120px;
      padding: 5px 8px;
      font-size: 13px;
      line-height: 17px;
    }
    .product-items .action-link {
      width: 22px;
      height: 22px;
      top: 4px;
      right: 4px;
      padding: 2px;
    }
    .product-items .action-link.product-cart {    
      bottom: 4px;
      left: 4px;
    }
    .product-items .pricing-wrapper .offer {
      display: block;
      width: 100%;
    
    }
  }
  
  /* wishlist page */
  .wishlist-wrapper .product-items .product-item-details {
    text-align: center;
  }
  .wishlist-wrapper .product-items .product-name .product-link {
    font-size: 16px;
    line-height: 20px;
    color: #121212;
  }
  @media (max-width:767px) {
    .wishlist-wrapper .product-items .product-name .product-link {
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 8px;
    }  
  }
  
  
  /* category page */
  
  /* breadcrumbs */
  .MuiBreadcrumbs-root {
    padding: 30px 0;
    margin-bottom: 0;
  }
  .MuiBreadcrumbs-ol {
    font-size: 12px;
    line-height: 16px;
    color: #5d5d66;
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
  }
  .MuiBreadcrumbs-root .MuiBreadcrumbs-li a {
    font: inherit;
    color: inherit;
  }
  .MuiBreadcrumbs-root .MuiBreadcrumbs-li p {
    font: inherit;
    font-weight: 500;
    color: #121212;
  }
  
  /* pagination */
  .pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .pagination-wrapper .pages {
    font-size: 14px;
    line-height: 20px;
    color: #121212;
    font-weight: 400;
  }
  .pagination-action button {
    margin-right: 29px;
    width: 122px;
  }
  .pagination-action button:last-child {
    margin-right: 0;
  }
  .pagination-action button.next-btn {
    width: 104px;
  }
  .pagination {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
  }
  .pagination li {
    margin-right: 6px;
  }
  .pagination li:last-child {
    margin-right: 0;
  }
  .pagination li a {
    font-size: 14px;
    line-height: 18px;
    color: #121212;
    font-weight: 600;
    text-decoration: none;
    min-width: 22px;
    height: 30px;
    padding: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .pagination li a:hover {
    color: #a5a5a5;
  }
  .pagination li.active a {
    background: #121212;
    color: #ffffff;
  }
  
  .category-banner {
    position: relative;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    margin-bottom: 18px;
  }
  .category-banner:before {
    content: "";
    background-image: linear-gradient(92deg, #121212 -50%, rgba(255,255,255,0) 100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
  .category-banner .banner-inner {
    position: relative;
  }
  .category-banner .product-name {
    font-size: 36px;
    line-height: 46px;
    color: #ffffff;
    font-weight: 600;
    margin: 0;
  }
  .category-banner .MuiBreadcrumbs-ol {
    color: #ffffff;
  }
  .category-banner .MuiBreadcrumbs-root {
    margin-bottom: 11px;
  }
  .category-banner .MuiBreadcrumbs-root .MuiBreadcrumbs-li p {  
    font-weight: normal;
    color: #ffffff;
  }
  
  .page-heading {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 30px;
    border-bottom: 1px solid #e3e3e3;
    text-transform: uppercase;
  }
  .page-heading .page-title {
    font-size: 18px;
    line-height: 22px;
    color: #121212;
    font-weight: 700;
  }
  .page-heading .page-right .select-border {
    margin-right: 0;
    min-width: 300px;
  }
  
  .sidebar {
    padding-right: 12px;
    max-width: 22.9%;
    flex-basis: 22.9%;
  }
  .sidebar .MuiAccordionSummary-root {
    padding: 15px 0;
    min-height: unset;
  }
  .sidebar .MuiAccordionSummary-root .MuiIconButton-root {
    padding: 0;
    margin: 0 8px;
    color: #c3c3c3;
  }
  .sidebar .MuiAccordionSummary-content {
    margin: 0;
  }
  .sidebar .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .sidebar .MuiAccordionSummary-root.Mui-expanded {
    min-height: unset;
  }
  .sidebar .MuiAccordion-root:first-child .MuiAccordionSummary-root {
    padding-top: 0;
  }
  .sidebar .MuiAccordion-root:before {
    background: #e3e3e3;
  }
  .sidebar .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .sidebar .MuiAccordionDetails-root {
    padding: 1px 0 12px;
    border-bottom: 1px solid #e3e3e3;
  }
  .sidebar .accordion-heading .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    justify-content: space-between; 
  }
  .sidebar .filter-heading {
    font-size: 16px;
    line-height: 22px;
    color: #121212;
    font-weight: 600;
  }
  .sidebar .filter-icon {
    padding: 0;
    margin: 0 8px;
    color: #c3c3c3;
    display: flex;
    height: 24px;  
  }
  .sidebar .filter-icon .remove-icon {
    display: none;
  }
  .sidebar .accordion-heading.Mui-expanded .filter-icon .add-icon {
    display: none;
  }
  .sidebar .accordion-heading.Mui-expanded .filter-icon .remove-icon {
    display: block;
  }
  .sidebar .sidebar-filter {
    margin: 0;
    width: 100%;
  }
  .sidebar .price.sidebar-filter {
    margin: 0;
    width: 90%;
    margin: 5px 10px;
  }
  .sidebar .sidebar-filter li {
    margin-bottom: 9px;
  }
  .sidebar .sidebar-filter li:last-child {
    margin-bottom: 0;
  }
  .sidebar .sidebar-filter .MuiCheckbox-root .MuiSvgIcon-root {
    width: 22px;
    height: 22px;
  }
  .sidebar .sidebar-filter .MuiCheckbox-root {
    margin-right: 10px !important;
  }
  .sidebar .sidebar-filter .MuiFormControlLabel-root {
    margin-left: 0;
    font-size: 14px;
    line-height: 16px;
    color: #121212;
    font-weight: 400;
  }
  .sidebar .sidebar-filter .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font: inherit;
    color: inherit;
  }
  
  .category-right {
    border-left: 4px solid #e3e3e3;
    padding-left: 55px;
    max-width: 77.1%;
    flex-basis: 77.1%;
  }
  .category-columns .product-items {
    margin: 0 -20px;
  }
  .category-columns .product-items .product-item {
    padding: 0 20px;
  }
  .category-columns .product-items .action-link {
    width: 30px;
    height: 30px;
  }
  .filter-summary {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 0 15px;
  }
  .filter-summary .filter-list {
    font-size: 12px;
    line-height: 16px;
    color: #121212;
    font-weight: 400;
    border: 1px solid #d3d3d3;
    min-height: 25px;
    padding: 4px;
    margin-right: 12px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }
  .filter-summary .filter-list.active,
  .filter-summary .filter-list:hover {
    background: #eeeeee;
  }
  .filter-summary .filter-list .cross-icon {
    margin-left: 5px;
    height: 11px;
  }
  
  @media (max-width: 1366px) {
    .category-right {
      padding-left: 30px;
    }
    .category-columns .product-items {
      margin: 0 -15px;
    }
    .category-columns .product-items .product-item {
      padding: 0 15px;
    }
  }
  @media (max-width: 1199px) {
    .MuiBreadcrumbs-root {
      padding: 20px 0;  
    }
  
    .page-heading .page-title {
      font-size: 16px;
      line-height: 20px;
    }
  
    .sidebar .filter-heading {
      font-size: 14px;
      line-height: 20px;
    }
    .sidebar .MuiAccordionSummary-root {
      padding: 12px 0;
    }
  
    .category-banner {   
      height: 180px;
    }
    .category-banner .product-name {
      font-size: 30px;
      line-height: 40px;
    }
    .category-columns .product-items {
      margin: 0 -10px;
    }
    .category-columns .product-items .product-item {
      padding: 0 10px;
    }
    .category-right {
      padding-left: 16px;
    }
  }
  @media (max-width: 991px) {
    .pagination-wrapper {
      justify-content: center;
    }
    .pagination {
      width: 100%;
      justify-content: center;
    }  
    .pagination-action button {
      margin-right: 15px;
    }
  
    .page-heading .page-right .select-border {
      min-width: 250px;
    }
  
    .sidebar {
      padding-right: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  
    .category-right {
      padding-left: 0;
      padding-top: 16px;
      width: 100%;
      border-left: 0;
      border-top: 2px solid #e3e3e3;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  @media (max-width: 767px) {
    .MuiBreadcrumbs-root {
      padding: 15px 0;
    }
  
    .pagination li {
      margin-right: 3px;
    }
  
    .page-heading .page-title {
      width: 100%;
      margin-bottom: 10px;
    }
    .page-heading .page-right {
      width: 100%;
    }
    .page-heading .page-right .select-border {
      min-width: unset;
      width: 100%;
      margin-bottom: 0;
    }
  
    .sidebar .sidebar-filter li {
      margin-bottom: 5px;
    }
    .sidebar .MuiAccordionSummary-root {
      padding: 8px 0;
    }
  
    .category-banner {
      height: 150px;
    }
    .category-banner .product-name {
      font-size: 24px;
      line-height: 28px;
    }
    .category-columns .product-items .action-link {
      width: 24px;
      height: 24px;
    }
    .category-columns .product-items {
      margin: 0 -7px;
    }
    .category-columns .product-items .product-item {
      padding: 0 7px;
    }
  }
  
  
  /* product details  */
  .product-info-wraper a {
    text-decoration: none;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  .product-info-wraper a:hover {
    text-decoration: none;
  }
  .product-info-wraper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .product-info-wraper .product-media {
    width: 52.3%;
  }
  .product-info-wraper .product-info-main {
    width: 47.7%;
    padding-left: 60px;
  }
  .product-info-wraper .product-title {
    font-size: 20px;
    line-height: 24px;
    color: #121212;
    font-weight: 600;
    margin: 0 0 9px;
    text-transform: uppercase;
  }
  .product-info-wraper .product-name {
    font-size: 18px;
    line-height: 22px;
    color: #5d5d66;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .product-info-wraper .rating {
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    color: #121212;
    font-weight: 500;
    border: 1px solid #d3d3d3;
    padding: 4px 7px;
    margin-bottom: 10px;
  }
  .product-info-wraper .rating .rating-star {
    width: 16px;
    height: 17px;
    margin-left: 5px;
  }
  .product-info-wraper .rating .rating-star img {
    height: 100%;
    max-width: 100%;
    display: block;
  }
  .product-info-wraper .pricing-wrapper {
    padding-top: 14px;
    margin-bottom: 32px;
    border-top: 1px solid #e3e3e3;
   
  }
  .product-info-wraper .pricing-wrapper .price {
    font-size: 24px;
    line-height: 30px;
    color: #121212;
    font-weight: 600;
    margin-right: 15px;
  }
  .product-info-wraper .pricing-wrapper .old-price {
    font-size: 18px;
    line-height: 22px;
    text-decoration: line-through;
    color: #a5a5a5;
    font-weight: normal;
    
  }
  .product-info-wraper .pricing-wrapper .offer {
    font-size: 18px;
    line-height: 22px;
    color: #ff4949;
    font-weight: 400;
    text-transform: uppercase;
    
  }
  .product-info-wraper .product-size-info {
    margin-bottom: 50px;
  }
  .product-info-wraper .size-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19px;
  }
  .product-info-wraper .size-heading .size-title {
    font-size: 16px;
    line-height: 20px;
    color: #121212;
    font-weight: 600;
    margin: 0;
  }
  .product-info-wraper .size-heading .size-guide {
    font-size: 14px;
    line-height: 18px;
    color: #121212;
    font-weight: 400;
    margin-left: 10px;
  }
  .product-info-wraper .size-heading .size-guide:hover {
    color: #a5a5a5;
  }
  .product-info-wraper .size-choose {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  .product-info-wraper .size-choose .size-list {
    width: 50px;
    height: auto;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    color: #121212;
    border: 1px solid #d3d3d3;
    font-weight: 600;
    margin-right: 10px;
  }
  .product-info-wraper .size-choose .size-list:last-child {
    margin-right: 0;
  }
  .product-info-wraper .size-choose .size-list.selected,
  .product-info-wraper .size-choose .size-list:hover {
    border: 1px solid #121212;
  }
  .product-info-wraper .size-choose .size-list a {
    color: inherit;
    text-align: center;
  }
  .product-info-wraper .product-actions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
  }
  .product-info-wraper .product-actions button {
    min-width: 222px;
    margin-right: 30px;
    min-height: 50px;
    text-transform: uppercase;
  }
  .product-info-wraper .product-actions button:last-child {
    margin-right: 0;
  }
  .product-info-wraper .product-description {
    border-top: 1px solid #e3e3e3;
    padding-top: 30px;
  }
  .product-info-wraper .desc-title {
    font-size: 16px;
    line-height: 20px;
    color: #121212;
    font-weight: 600;
    margin: 0 0 10px;
  }
  .product-info-wraper .desc-content {
    font-size: 16px;
    line-height: 22px;
    color: #121212;
    font-weight: 400;
    margin: 0 0 30px;
  }
  .product-info-wraper .desc-content:last-child {
    margin-bottom: 20px;
  }
  .product-info-wraper .desc-detail {
    margin: 0 0 30px;
  }
  .product-info-wraper .desc-detail li {
    display: flex;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
    color: #121212;
    font-weight: 400;
  }
  .product-info-wraper .desc-detail li:last-child {
    margin-bottom: 0;
  }
  .product-info-wraper .desc-detail .title {
    width: 20%;
    color: #a5a5a5;
  }
  .product-info-wraper .desc-detail .value {
    width: 80%; 
  }
  .product-info-wraper .product-about {
    border-top: 1px solid #e3e3e3;
    padding-top: 30px;
    margin-bottom: 13px;
  }
  .product-info-wraper .product-about .desc-title {
    margin-bottom: 16px;
  }
  .product-info-wraper .about-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }
  .product-info-wraper .about-row .product-img {
    width: 80px;
    height: 100px;
    border: 1px solid #d3d3d3;
    overflow: hidden;
  }
  .product-info-wraper .about-row .product-img img {
    width: 100%;
    height: 100%;
  }
  .product-info-wraper .about-row .product-desc {
    flex: 1;
    padding-left: 10px;
    font-size: 16px;
    line-height: 22px;
    color: #121212;
    font-weight: 400;
  }
  .product-info-wraper .ratings-wrapper {
    margin-bottom: 20px;
    padding-top: 30px;
    border-top: 1px solid #e3e3e3;
  }
  .product-info-wraper .ratings-wrapper .rating {
    font-size: 36px;
    line-height: 48px;
    color: #121212;
    font-weight: 500;
    margin-bottom: 9px;
    padding: 0;
    border: 0;
  }
  .product-info-wraper .ratings-wrapper .rating .rating-star {
    width: 25px;
    height: 25px;
    margin-left: 9px;
  }
  .product-info-wraper .desc-count {
    font-size: 14px;
    line-height: 18px;
    color: #121212;
    font-weight: 400;
  }
  .product-info-wraper .delivery-detail {
    border-top: 1px solid #e3e3e3;
    padding-top: 30px;
  }
  .product-info-wraper .product-reviews {
    border-top: 1px solid #e3e3e3;
    padding-top: 32px;
  }
  .product-info-wraper .product-reviews .desc-title {
    margin-bottom: 14px;
  }
  .product-info-wraper .review-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
  }
  .product-info-wraper .review-wrapper .rating {
    background: #ffc106;
    border: 1px solid #ffc106;
    color: #ffffff;
    padding: 0px 4px;
  }
  .product-info-wraper .review-wrapper .review-content {
    flex: 1;
    padding-left: 10px;
  }
  .product-info-wraper .review-wrapper .product-desc {
    font-size: 14px;
    line-height: 18px;
    color: #5d5d66;
    font-weight: 400;
    margin-bottom: 24px;
  }
  .product-info-wraper .review-wrapper .thumb-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  .product-info-wraper .review-wrapper .thumb-image {
    width: 90px;
    height: 100px;
    margin-right: 16px;
  }
  .product-info-wraper .review-wrapper .thumb-image:last-child {
    margin-right: 0;
  }
  .product-info-wraper .review-wrapper .thumb-image img {
    width: 100%;
    height: 100%;
  }
  .product-info-wraper .review-wrapper .reviewer {
    font-size: 14px;
    line-height: 18px;
    color: #121212;
    font-weight: 400;
  }
  .product-info-wraper .review-wrapper .reviewer .review-date {
    border-left: 1px solid #121212;
    padding-left: 5px;
    margin-left: 5px;
  }
  .product-info-wraper .view-link {
    font-size: 14px;
    line-height: 18px;
    color: #121212;
    font-weight: 600;
  }
  .product-info-wraper .view-link:hover {
    color: #a5a5a5;
  }
  .product-carousel .product-heading {
    font-size: 16px;
    line-height: 20px;
    color: #121212;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 24px;
  }
  .product-carousel {
    margin-bottom: 46px;
  }
  .product-carousel .product-items {
    margin: 0 -13px;
  }
  .product-carousel .product-items .product-item {
    padding: 0 13px;
    margin-bottom: 0;
  }
  
  
  .thumb-slider .carousel .control-prev.control-arrow:before,
  .thumb-slider .carousel .control-next.control-arrow:before {
    border: 0;
    background-repeat: no-repeat;
    width: 20px;
    height: 30px;
  }
  .thumb-slider .carousel .thumb {
    width: 64px;
    height: 80px;
    border: 1px solid #d3d3d3;
    padding: 0;
  }
  .thumb-slider .carousel .thumb img {
    height: 100%;
  }
  .thumb-slider .carousel .thumb.selected, 
  .thumb-slider .carousel .thumb:hover {
    border: 0;
    opacity: 0.5;
  }
  
  /* image gallery thumb */
  .image-gallery .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail, 
  .image-gallery .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails .image-gallery-thumbnail+.image-gallery-thumbnail {
    margin-top: 10px;
  }
  .image-gallery .image-gallery-thumbnail.active, 
  .image-gallery .image-gallery-thumbnail:focus
  .image-gallery .image-gallery-thumbnail:hover {
    border: 1px solid #d3d3d3; 
    opacity: 0.5;
  }
  .image-gallery .image-gallery-thumbnail {
    width: 64px;
    border: 1px solid #d3d3d3;  
  }
  .image-gallery .image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails, 
  .image-gallery .image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    position: static;
    max-height: 440px;
  }
  .image-gallery .image-gallery-thumbnails-wrapper.left, 
  .image-gallery .image-gallery-thumbnails-wrapper.right {
    width: 212px;
    padding: 75px 0;
    margin: 0;
  }
  .image-gallery .image-gallery-slide-wrapper.left, 
  .image-gallery .image-gallery-slide-wrapper.right {
    width: calc(100% - 212px);
  }
  .image-gallery .image-gallery-left-nav, 
  .image-gallery .image-gallery-right-nav {
    padding: 0;
  }
  .image-gallery .image-gallery-left-nav .image-gallery-svg, 
  .image-gallery .image-gallery-right-nav .image-gallery-svg {
    height: 55px;
    width: 55px;
  }
  .image-gallery .image-gallery-icon {
    filter: unset;
    color: #121212;
  }
  .image-gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
    padding: 0 58px;
  }
  .image-gallery .image-gallery-slide .image-gallery-image {
    object-fit: fill;
    /* object-fit: cover; */
  }
  @media (hover: hover) and (pointer: fine) {
    .image-gallery .image-gallery-thumbnail:hover {
      border: 1px solid #d3d3d3; 
    }
  }
  
  @media (max-width: 1280px) {
    /* image gallery thumb */
    .image-gallery .image-gallery-thumbnails-wrapper.left, 
    .image-gallery .image-gallery-thumbnails-wrapper.right {
      width: 150px;
    }
    .image-gallery .image-gallery-slide-wrapper.left, 
    .image-gallery .image-gallery-slide-wrapper.right {
      width: calc(100% - 150px);
    }
    .image-gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
      padding: 0 30px;
    }
  }
  @media (max-width: 1199px) {
    .product-info-wraper .product-info-main {
      padding-left: 30px;
    }
    .product-info-wraper .product-title {
      font-size: 18px;
      line-height: 22px;
    }
    .product-info-wraper .product-name {
      font-size: 16px;
      line-height: 20px;
    }
    .product-info-wraper .pricing-wrapper {
      margin-bottom: 22px;
    }
    .product-info-wraper .pricing-wrapper .price {
      font-size: 20px;
      line-height: 26px;
      margin-right: 10px;
    }
    .product-info-wraper .pricing-wrapper .old-price {
      font-size: 16px;
      line-height: 20px;
    }
    .product-info-wraper .pricing-wrapper .offer {
      font-size: 16px;
      line-height: 20px;
    }
    .product-info-wraper .product-size-info {
      margin-bottom: 30px;
    }
    .product-info-wraper .product-actions button {
      min-width: 180px;
      margin-right: 15px;
    }
    .product-info-wraper .desc-title {
      font-size: 14px;
      line-height: 18px;
    }
    .product-info-wraper .desc-content {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 20px;
    }
    .product-info-wraper .desc-detail li {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }
    .product-info-wraper .ratings-wrapper .rating {
      font-size: 30px;
      line-height: 42px;
    }
    .product-carousel .product-items {
      margin: 0 -10px;
    }
    .product-carousel .product-items .product-item {
      padding: 0 10px;
    }
  
    /* image gallery thumb */
    .image-gallery .image-gallery-thumbnails-wrapper.left, 
    .image-gallery .image-gallery-thumbnails-wrapper.right {
      width: 100px;
    }
    .image-gallery .image-gallery-slide-wrapper.left, 
    .image-gallery .image-gallery-slide-wrapper.right {
      width: calc(100% - 100px);
    }
    .image-gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
      padding: 0 10px;
    }
  }
  
  @media (max-width: 991px) {
    .product-info-wraper .product-media {
      width: 100%;
    }
    .product-info-wraper .product-info-main {  
      width: 100%;
      padding-left: 0;
      margin-top: 20px;
    }
  
    /* image gallery thumb */
    .image-gallery .image-gallery-thumbnails-wrapper.left, 
    .image-gallery .image-gallery-thumbnails-wrapper.right {
      width: 170px;
    }
    .image-gallery .image-gallery-slide-wrapper.left, 
    .image-gallery .image-gallery-slide-wrapper.right {
      width: calc(100% - 170px);
    }
    .image-gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
      padding: 0 30px;
    }
  }
  @media (max-width: 767px) {
    .product-info-wraper .product-title {
      font-size: 16px;
      line-height: 20px;
    }
    .product-info-wraper .product-name {
      font-size: 14px;
      line-height: 18px;
    }
    .product-info-wraper .pricing-wrapper .price {
      font-size: 16px;
      line-height: 22px;
    }
    .product-info-wraper .pricing-wrapper .old-price {
      font-size: 14px;
      line-height: 18px;
    }
    .product-info-wraper .pricing-wrapper .offer {
      font-size: 14px;
      line-height: 18px;
    }
    .product-info-wraper .size-heading {   
      margin-bottom: 12px;
    }
    .product-info-wraper .size-choose .size-list {
      width: 42px;
      height: 42px;
      margin-right: 6px;
    }
    .product-info-wraper .product-actions button {
      min-width: unset;
      margin-right: 2%;
      width: 48%;
    }
    .product-info-wraper .product-description {
      padding-top: 20px;
    }
    .product-info-wraper .desc-detail {
      margin: 0 0 20px;
    }
    .product-info-wraper .delivery-detail {
      padding-top: 20px;
    }
    .product-info-wraper .product-about {
      padding-top: 20px;
    }
    .product-info-wraper .about-row .product-img {
      width: 65px;
      height: 85px;
    }
    .product-info-wraper .about-row .product-desc {
      font-size: 14px;
      line-height: 20px;
    }
    .product-info-wraper .ratings-wrapper {
      padding-top: 20px;
    }
    .product-info-wraper .ratings-wrapper .rating {
      font-size: 25px;
      line-height: 37px;
    }
    .product-info-wraper .product-reviews {
      padding-top: 20px;
    }
    .product-info-wraper .review-wrapper .thumb-image {
      width: 75px;
      height: 85px;
      margin-right: 10px;
    }
    .product-info-wraper {
      margin-bottom: 30px;
    }
    .product-carousel .product-heading {
      margin: 0 0 15px;
    }
    .product-carousel {
      margin-bottom: 25px;
    }
    .product-carousel .product-items {
      margin: 0 -7px;
    }
    .product-carousel .product-items .product-item {
      padding: 0 7px;
    }
  }
  @media (max-width: 575px) {
    /* image gallery thumb */
    .image-gallery .image-gallery-thumbnails-wrapper.left, 
    .image-gallery .image-gallery-thumbnails-wrapper.right {
      width: 87px;
      padding: 0;
    }
    .image-gallery .image-gallery-slide-wrapper.left, 
    .image-gallery .image-gallery-slide-wrapper.right {
      width: calc(100% - 87px);
    }
    .image-gallery .image-gallery-thumbnails .image-gallery-thumbnails-container {
      padding: 0;
    }
    .image-gallery-content .image-gallery-slide .image-gallery-image {
      height: 340px;
    }
    .image-gallery .image-gallery-left-nav .image-gallery-svg, 
    .image-gallery .image-gallery-right-nav .image-gallery-svg {
      height: 40px;
      width: 35px;
    }
  }
  
  
  /* flickity */
  .carousel-cell {
    background: #8c8;
    counter-increment: carousel-cell;
    color: white;
  }
  .carousel-cell:before {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    text-align: center;
    content: counter(carousel-cell);
  }
  .carousel-main .carousel-cell {
    width: 100%;
    height: 360px;
    font-size: 5rem;
  }
  .carousel-nav {
    width: 300px;
    transform: rotate(90deg) translate(30px, -100%);
    transform-origin: left top;
  }
  .carousel-nav .carousel-cell {
    transform: rotate(-90deg);
    width: 80px;
    height: 80px;
    cursor: pointer;
    margin-right: 1rem;
    font-size: 1.4rem;
  }
  .carousel-nav .carousel-cell.is-nav-selected {
    background: #ed2;
  }
  .carousel-nav .flickity-prev-next-button {
    width: 40px;
    height: 40px;
    background: transparent;
  }
  .carousel-nav .flickity-prev-next-button.previous {
    left: -40px;
  }
  .carousel-nav .flickity-prev-next-button.next {
    right: -40px;
  }
  @media screen and (max-width: 1200px) {
    .carousel-nav {
      transform: rotate(90deg) translate(30px, -50%);
    }
  }
  
