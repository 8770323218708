.MuiRadio-colorSecondary.Mui-checked {
    color: black !important;
}

input {
    outline: 0;
    border-width: 0 0 1px;    
    width: 100%;
}

input:focus {
    border-color: blue;
    border-width: 0 0 2px; 
  }

.google-button {
    background-color: white !important;
    border: 0.5px solid black !important;
    text-transform: inherit  !important;
    color: black !important;
}

.google-button:hover {
    background-color: black !important;
    border: 0.5px solid black !important;
    text-transform: inherit  !important;
    color: white !important;
}